import PropTypes from "prop-types";
import React from "react";
import NavLink from "../NavLink";

const FooterLinkItem = ({ data }) => {
  return (
    <div className="footer-widget">
      <h4 className="footer-widget-title">{data?.heading}</h4>
      <div className="footer-widget-content">
        <ul>
          {data?.menu?.map((single, key) => (
            <li key={key}>
              {/* <Link to={single.url}>
                {single.text}{" "}
                {single?.badge && (
                  <span className="ft-badge">{single.badge}</span>
                )}{" "}
              </Link> */}
              <NavLink
                type={single.type}
                link={single.link}
                label={single.label}
              />
            </li>
          ))}
        </ul>
      </div>
    </div>
  );
};

FooterLinkItem.propTypes = {
  data: PropTypes.object,
};

export default FooterLinkItem;
