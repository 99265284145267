import React from "react";
// import { Link as NavLink } from "gatsby";
import NavLink from "../NavLink";

const NavBar = ({ list }) => {
  return (
    <nav className="site-main-menu">
      <ul>
        {list?.map((item, index) => (
          <li key={index}>
            <NavLink type={item.type} link={item.link} label={item.label} />
          </li>
        ))}
      </ul>
    </nav>
  );
};

export default NavBar;
