import { Link } from "gatsby";
import PropTypes from "prop-types";
import React from "react";

const Logo = ({ image, type, text }) => {
  return (
    <div className="header-logo">
      {type === "img" ? (
        <Link to={"/"}>
          <img
            className="dark-logo"
            src={`${image?.asset.url}?auto=format&w=200`}
            alt={image?.alt}
          />
        </Link>
      ) : (
        <h1 className="logoText">{text}</h1>
      )}
    </div>
  );
};

Logo.propTypes = {
  image: PropTypes.object,
};

export default Logo;
