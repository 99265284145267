import React from "react";
// import { Link as NavLink } from "gatsby";
// import { getClosest, getSiblings, slideToggle, slideUp } from "../../../utils";
import NavLink from "../../NavLink";

const MobileNavMenu = ({ list, sociallinks, onClose }) => {
  // const onClickHandler = (e) => {
  //   const target = e.currentTarget;
  //   const parentEl = target.parentElement;
  //   if (
  //     parentEl?.classList.contains("menu-toggle") ||
  //     target.classList.contains("menu-toggle")
  //   ) {
  //     const element = target.classList.contains("icon") ? parentEl : target;
  //     const parent = getClosest(element, "li");
  //     const childNodes = parent.childNodes;
  //     const parentSiblings = getSiblings(parent);
  //     parentSiblings.forEach((sibling) => {
  //       const sibChildNodes = sibling.childNodes;
  //       sibChildNodes.forEach((child) => {
  //         if (child.nodeName === "UL") {
  //           slideUp(child, 1000);
  //         }
  //       });
  //     });
  //     childNodes.forEach((child) => {
  //       if (child.nodeName === "UL") {
  //         slideToggle(child, 1000);
  //       }
  //     });
  //   }
  // };
  return (
    <nav className="site-mobile-menu">
      <ul>
        {/*className="has-children"*/}
        {list?.map((item, index) => (
          <li key={index} onClick={onClose}>
            <NavLink type={item.type} link={item.link} label={item.label} />
          </li>
        ))}
        <li className="social-links">
          {sociallinks.map((item, index) => (
            <a href={item?.url} target="_blank" key={index} aria-label="link">
              <div
                dangerouslySetInnerHTML={{ __html: item.icon }}
                className="flex justify-center"
              />
            </a>
          ))}
        </li>
      </ul>
    </nav>
  );
};

export default MobileNavMenu;
