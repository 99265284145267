import { graphql, useStaticQuery } from "gatsby";
import React, { useEffect } from "react";
import ScrollToTop from "../ScrollToTop";
import Footer from "./Footer";
import Header from "./Header";

export default function Layout({ children }) {
  useEffect(() => {
    const AOS = require("aos");
    AOS.init({
      once: true,
    });
  }, []);
  const { sanitySitesettings } = useStaticQuery(
    graphql`
      query {
        sanitySitesettings {
          favicon {
            asset {
              url
            }
          }
          scroll {
            hex
          }

          header {
            logoType
            logoText
            logo {
              alt
              asset {
                url
              }
            }
            menu {
              label
              link
              type
            }
            social {
              icon
              name
              url
            }
          }
          footer {
            logo {
              alt
              asset {
                url
              }
            }
            menu {
              heading
              menu {
                label
                link
                type
              }
            }
            social {
              icon
              name
              url
            }
            contact {
              value
              link
              inputType
            }
            _rawCc
          }
        }
      }
    `
  );

  return (
    <>
      <Header data={sanitySitesettings?.header} />
      <main>{children}</main>
      <ScrollToTop color={sanitySitesettings?.scroll} />

      <Footer data={sanitySitesettings?.footer} />
    </>
  );
}
