import React, { Fragment, useEffect, useState } from "react";
import NavBar from "../NavBar/NavBar";
import Logo from "../logo/Logo";
// import HeaderSearch from "../HeaderSearch/HeaderSearch";
import MainSearch from "../NavBar/MainSearch";
import MobileMenu from "../NavBar/MobileMenu";

const Header = ({ data }) => {
  const [ofcanvasShow, setOffcanvasShow] = useState(false);
  const onCanvasHandler = () => {
    setOffcanvasShow((prev) => !prev);
  };
  const [searchbarShow, setSearchbarShow] = useState(false);
  const onSearchHandler = () => {
    setSearchbarShow((prev) => !prev);
  };
  const [scroll, setScroll] = useState(0);
  const [headerTop, setHeaderTop] = useState(0);

  useEffect(() => {
    const header = document.querySelector(".header-section");
    setHeaderTop(header.offsetTop);
    window.addEventListener("scroll", handleScroll);
    return () => {
      window.removeEventListener("scroll", handleScroll);
    };
  }, []);

  const handleScroll = () => {
    setScroll(window.scrollY);
  };

  // ${ scroll > headerTop ? "is-sticky" : "" }
  return (
    <Fragment>
      <div
        className={`header-section header-transparent sticky-header section is-sticky`}
      >
        <div className="header-inner">
          <div className="container position-relative">
            <div className="row justify-content-between align-items-center">
              <div className="col-xl-2 col-auto order-0">
                <Logo
                  image={data?.logo}
                  type={data?.logoType}
                  text={data?.logoText}
                />
              </div>
              <div className="col-auto col-xl d-flex align-items-center justify-content-xl-center justify-content-end order-2 order-xl-1">
                <div className="menu-column-area d-none d-xl-block position-static">
                  <NavBar list={data?.menu} />
                </div>
                <div className="header-search-area ms-xl-7 ms-0">
                  {/* <HeaderSearch onClick={onSearchHandler} /> */}
                </div>

                <div className="header-mobile-menu-toggle d-xl-none ms-sm-2">
                  <button
                    type="button"
                    className="toggle"
                    onClick={onCanvasHandler}
                    aria-label="button"
                  >
                    <i className="icon-top"></i>
                    <i className="icon-middle"></i>
                    <i className="icon-bottom"></i>
                  </button>
                </div>
              </div>
              <div className="d-none d-xl-block col-xl-2 col-auto order-2 social-links">
                {data?.social.map((item, index) => (
                  <a
                    href={item?.url}
                    target="_blank"
                    key={index}
                    aria-label="link"
                  >
                    <div
                      dangerouslySetInnerHTML={{ __html: item.icon }}
                      className="flex justify-center"
                    />
                  </a>
                ))}
              </div>
            </div>
          </div>
        </div>
      </div>
      <MobileMenu data={data} show={ofcanvasShow} onClose={onCanvasHandler} />
      <MainSearch show={searchbarShow} onClose={onSearchHandler} />
    </Fragment>
  );
};

export default Header;
