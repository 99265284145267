import React from "react";
import { Link } from "gatsby";

export default function NavLink({ type, link, label }) {
  if (type === "internal")
    return (
      <Link to={link}>
        {" "}
        <span className="menu-text">{label}</span>
      </Link>
    );
  else
    return (
      <a href={link} target="_blank">
        <span className="menu-text">{label}</span>
      </a>
    );
}
